import * as React from 'react';
import styled from 'styled-components';

export const ProjectList = styled.div`
  max-width: 750px;
  margin: ${props => props.theme.spacing(4, 0)};
  width: 100%;

  & > * {
    margin: ${props => props.theme.spacing(3, 0)};
  }
`