import * as React from 'react';
import styled from 'styled-components';
import { Typography, TypographyProps } from 'components/Typography';

const ProjectListItemTextTypography = styled(Typography)`

`

export type ProjectListItemTextProps = Omit<TypographyProps, 'variant'>;

export const ProjectListItemText: React.FC<ProjectListItemTextProps> = (props) => (
  <ProjectListItemTextTypography {...props} />
)