import * as React from 'react';
import styled from 'styled-components';


export interface FlexBoxProps {
  flexDirection?: React.CSSProperties['flexDirection'];
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`

export type ColumnProps = Omit<FlexBoxProps, 'flexDirection'>;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`

export type RowProps = Omit<FlexBoxProps, 'flexDirection'>;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`