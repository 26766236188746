import { graphql, useStaticQuery } from "gatsby";
import { FixedObject } from "gatsby-image";

export const miniFixedImage = graphql`
  fragment miniFixedImage on File {
    childImageSharp {
      fixed(
        quality: 100
        width: 960
      ) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

const projectsDesignImagesQuery = graphql`
  query projectsDesignImages {
    nowaUapMiniImg: file(relativePath: { eq: "nowa-uap-mini.jpg" }) {
      ...miniFixedImage
    }
    vasesMiniImg: file(relativePath: { eq: "vases-mini.jpg" }) {
      ...miniFixedImage
    }
    woodenStoolMiniImg: file(relativePath: { eq: "wooden-stool-mini.png" }) {
      ...miniFixedImage
    }
    quoQuoMiniImg: file(relativePath: { eq: "quoquo-mini.jpg" }) {
      ...miniFixedImage
    }
    ceramicTilesMiniImg: file(relativePath: { eq: "ceramic-tiles-mini.jpg" }) {
      ...miniFixedImage
    }
    chessFiguresMiniImg: file(relativePath: { eq: "chess-figures-mini.png" }) {
      ...miniFixedImage
    }
    scooterMiniImg: file(relativePath: { eq: "scooter-mini.jpg" }) {
      ...miniFixedImage
    }
    britaMiniImg: file(relativePath: { eq: "brita-mini.jpg" }) {
      ...miniFixedImage
    }
    infuserMiniImg: file(relativePath: { eq: "infuser-mini.jpg" }) {
      ...miniFixedImage
    }
  }
`

export interface FixedProjectImageData {
  childImageSharp: {
    fixed: FixedObject;
  }
}

export interface ProjectsDesignImagesQuery {
  nowaUapMiniImg: FixedProjectImageData;
  vasesMiniImg: FixedProjectImageData;
  woodenStoolMiniImg: FixedProjectImageData;
  quoQuoMiniImg: FixedProjectImageData;
  ceramicTilesMiniImg: FixedProjectImageData;
  chessFiguresMiniImg: FixedProjectImageData;
  scooterMiniImg: FixedProjectImageData;
  britaMiniImg: FixedProjectImageData;
  infuserMiniImg: FixedProjectImageData;
}

export const useProjectsDesignImagesQuery = () => {
  const images = useStaticQuery<ProjectsDesignImagesQuery>(projectsDesignImagesQuery);
  
  return images;
}
