import { graphql, useStaticQuery } from "gatsby";
import { FixedObject } from "gatsby-image";

const avatarFixedImageQuery = graphql`
  query avatarFixedImageQuery {
    avatarImg: file(relativePath: { eq: "avatar.png" }) {
      childImageSharp {
        fixed(
          quality: 100
          width: 240
        ) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export interface FixedAvatarImageData {
  childImageSharp: {
    fixed: FixedObject;
  }
}

export interface AvatarImageQuery {
  avatarImg: FixedAvatarImageData;
}

export const useAvatarImageQuery = () => {
  const image = useStaticQuery<AvatarImageQuery>(avatarFixedImageQuery);

  return image;
}
