import * as React from 'react';
import styled from 'styled-components';

const StyledAnchorElement = styled.a`
  text-decoration: underline;
  font-weight: ${props => props.theme.typography.button.fontWeight};
  color: ${props => props.theme.colorPalette.primary};

  &:hover {
      color: ${props => props.theme.colorPalette.secondary};
    }
`

export type ExternalPageLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
export const ExternalPageLink: React.FC<ExternalPageLinkProps> = ({
  target = '_blank',
  ...props
}) => (
  <StyledAnchorElement {...props} target={target} />
)