import * as React from 'react';
import styled from 'styled-components';
import { Typography, TypographyProps } from 'components/Typography';

const ProjectListItemDateTypography = styled(Typography)`

`

export type ProjectListItemDateProps = Omit<TypographyProps, 'variant'>;

export const ProjectListItemDate: React.FC<ProjectListItemDateProps> = (props) => (
  <ProjectListItemDateTypography {...props} variant='caption' />
);