import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useSeoSiteQuery } from 'utils/useSeoSiteQuery'
import { useLocation } from '@reach/router';

export interface SEOProps {
  title?: string;
  description?: string;
}

export const SEO: React.FC<SEOProps> = ({ title, description }) => {
  const { pathname } = useLocation()

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } = useSeoSiteQuery(); 
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
    </Helmet>
  )
}