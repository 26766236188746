import * as React from 'react';
import styled from 'styled-components';

import { Typography, TypographyProps } from 'components/Typography';

const ProjectListItemTitleTypography = styled(Typography)`

`

export type ProjectListItemTitleProps = Omit<TypographyProps, 'variant'>;

export const ProjectListItemTitle: React.FC<ProjectListItemTitleProps> = (props) => (
  <ProjectListItemTitleTypography {...props} variant='header4' />
);