import * as React from 'react';
import styled from 'styled-components';

const AvatarImg = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 25%;

  @keyframes appear {
    0% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: 0.65s ease-out 0s 1 appear;
`

const AvatarContainer = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 25%;
  overflow: hidden;
`

export type AvatarProps = React.ImgHTMLAttributes<HTMLImageElement>;
export const Avatar: React.FC<AvatarProps> = (props) => (
  <AvatarContainer>
    <AvatarImg {...props} />
  </AvatarContainer>
)