import * as React from 'react';
import { PageContainer } from 'components/PageContainer';
import { Typography } from 'components/Typography';
import {
  ProjectList,
  ProjectListItem,
  ProjectListItemTitle,
  ProjectListItemDate,
  ProjectListItemText
} from 'components/ProjectList';
import { FullWidthDivider } from 'components/Divider';
import { ExternalPageLink } from 'components/ExternalPageLink';
import { ProjectsPageColumn } from './ProjectsPageColumn';
import { Translate } from 'components/Translate';

import monogram from './images/monogram.svg';
import { useProjectsDesignImagesQuery } from 'utils/useProjectsDesignImagesQuery';
import { useTranslation } from 'react-i18next';

export const ProjectsDesign: React.FC = () => {
  const images = useProjectsDesignImagesQuery();
  const { t: trans } = useTranslation();

  return (
    <PageContainer id='design_projects'>
      <ProjectsPageColumn alignItems='center' justifyContent='center'>
        <Typography variant='header1'><Translate label='topMenu.designProjects' /></Typography>
        <ProjectList>
          <ProjectListItem
            src={images.nowaUapMiniImg.childImageSharp.fixed.src}
          >
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.nowa' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2020</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='designProjectsList.desktopUI' />
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='https://nowa.netlify.com'><Translate label='common.here' /></ExternalPageLink>
                <Translate label='designProjectsList.previewImplementation' />
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/nowa_uap.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', {
                  format: `PDF (${trans('common.project')})`
                })}
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/nowa_banner.mp4'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toWatchFile', {
                  file: `MP4 (${trans('designProjectsList.siteBannerNowa')})`
                })}
              </Typography>
              <Typography component="span" variant="quote">
                {trans('common.createdIn', { workshop: trans('designProjectsList.workshops.interface') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.vasesMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.stonewareVases' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2019</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='designProjectsList.solidsGoal' />
                {' '}
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/vases.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', {
                  format: "PDF"
                })}
              </Typography>
              <Typography component='span' variant='quote'>
                {trans('common.createdDuringClasses', { classes: trans('designProjectsList.workshops.preliminaryDesign') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.chessFiguresMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.chess' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2020</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='designProjectsList.chessInspiration' />
                {' '}
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/chess.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', {
                  format: "PDF"
                })}
              </Typography>
              <Typography component='span' variant='quote'>
                {trans('common.createdIn', { workshop: trans('designProjectsList.workshops.ceramics') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.ceramicTilesMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.modularTiles' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2020</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='designProjectsList.modularTilesGoal' />
                {' '}
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/tiles.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', {
                  format: "PDF"
                })}
              </Typography>
              <Typography component="span" variant="quote">
                {trans('common.createdIn', { workshop: trans('designProjectsList.workshops.ceramics') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={monogram}>
            <ProjectListItemTitle>Monogram</ProjectListItemTitle>
            <ProjectListItemDate>2019</ProjectListItemDate>
            <ProjectListItemText>
              <Translate label='designProjectsList.monogramDescription' />
              <Typography component="span" variant="quote">
                {trans('common.createdDuringClasses', { classes: trans('designProjectsList.workshops.typographyBasics') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.quoQuoMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>QuoQuo</ProjectListItemTitle>
            <ProjectListItemDate>2020</ProjectListItemDate>
            <ProjectListItemText>
              <Translate label='designProjectsList.quoQuoDescription' />
              <Typography component="span" variant="quote">
                {trans('common.createdIn', { workshop: trans('designProjectsList.workshops.2ndDesignStudio') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.scooterMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.scooter' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2020</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/scooter.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', { format: "PDF" })}
                <Typography component="span" variant="quote">
                  {trans('common.createdDuringClasses', { classes: trans('designProjectsList.workshops.technicalDrawing') })}
                </Typography>
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.britaMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.brita' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2020</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/brita.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', { format: "PDF" })}
                <Typography component="span" variant="quote">
                  {trans('common.createdDuringClasses', { classes: trans('designProjectsList.workshops.technicalDrawing') })}
                </Typography>
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.infuserMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.teaInfuser' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2019</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/tea_infuser.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', { format: "PDF" })}
                <Typography component="span" variant="quote">
                  {trans('common.createdDuringClasses', { classes: trans('designProjectsList.workshops.technicalDrawing') })}
                </Typography>
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem src={images.woodenStoolMiniImg.childImageSharp.fixed.src}>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.woodenStool' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2019</ProjectListItemDate>
            <ProjectListItemText>
              <Typography>
                <Translate label='designProjectsList.woodenStoolDescription' />
                <Translate label='common.click' />
                {' '}
                <ExternalPageLink href='/stool.pdf'><Translate label='common.here' /></ExternalPageLink>
                {trans('common.toDownloadFile', { format: "PDF" })}
                <Typography component="span" variant="quote">
                  {trans('common.createdDuringClasses', { classes: trans('designProjectsList.workshops.preliminaryDesign') })}
                </Typography>
              </Typography>
            </ProjectListItemText>
          </ProjectListItem>
          {/* <FullWidthDivider />
          <ProjectListItem>
            <ProjectListItemTitle>
              <Translate label='designProjectsList.titles.consciousShopping' />
            </ProjectListItemTitle>
            <ProjectListItemDate>2019</ProjectListItemDate>
            <ProjectListItemText>
              <Translate label='designProjectsList.mobileUI' />
              <Typography component="span" variant="quote">
                {trans('common.createdIn', { workshop: trans('designProjectsList.workshops.interface') })}
              </Typography>
            </ProjectListItemText>
          </ProjectListItem> */}
        </ProjectList>
      </ProjectsPageColumn>
    </PageContainer>
  )
}