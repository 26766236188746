import * as React from 'react';
import { PageContainer } from 'components/PageContainer';
import { Typography } from 'components/Typography';
import styled from 'styled-components';
import { Avatar } from 'components/Avatar';
import { ExternalPageLink as ExternalLink } from 'components/ExternalPageLink';
import { Column } from 'components/FlexBox';
import { useAvatarImageQuery } from 'utils/useAvatarImageQuery';
import { Translate } from 'components/Translate';

const CenteredTypography = styled(Typography)`
  text-align: center;
  padding: ${props => props.theme.spacing(0, 1)};
`

const NameTypography = styled(CenteredTypography)`
  letter-spacing: 2.5px;
  margin: ${props => props.theme.spacing(3, 0, 1)};
`

const HeaderBlock = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 2)};
`

export const AboutMe: React.FC = () => {
  const avatarImageData = useAvatarImageQuery();

  return (
    <PageContainer id='about_me'>
      <Column alignItems='center' justifyContent='center'>
        <Avatar src={avatarImageData.avatarImg.childImageSharp.fixed.src} />
        <NameTypography variant='header2' textTransform='uppercase'>
          Mateusz Urbaniak
      </NameTypography>
        <HeaderBlock>
          <CenteredTypography variant='header3' color='textSecondary'>
            Front-End web developer
        </CenteredTypography>
        {/* <CenteredTypography variant='header4'>
            <Translate label='aboutMe.uapStudent' />
        </CenteredTypography> */}
        </HeaderBlock>
        <CenteredTypography color='textSecondary'>
          <Translate label='aboutMe.passionateSoftwareEngineer' />
          <ExternalLink href='https://www.betadistrict.com/'>Beta District</ExternalLink>
        .
      </CenteredTypography>
        <CenteredTypography color='textSecondary'>
          <Translate label='aboutMe.deliver' />
          <ExternalLink href='https://nodejs.org/en/'>Node.js</ExternalLink>
          {`, `}
          <ExternalLink href='https://reactjs.org/'>React.js</ExternalLink>
          {' '}<Translate label='common.and' />{' '}
          <ExternalLink href='https://www.typescriptlang.org/'>Typescript</ExternalLink>
        .
      </CenteredTypography>
        <CenteredTypography color='textSecondary'>
          <Translate label='aboutMe.bachelorOfComputing' />
      </CenteredTypography>
        <CenteredTypography color='textSecondary'>
          <Translate label='aboutMe.rightNow' />
      </CenteredTypography>
      </Column>
    </PageContainer>
  )
}