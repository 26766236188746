import * as React from 'react';
import styled from 'styled-components';

export interface ProjectListItemProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imgClassName?: string;
}

const ProjectListItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const ProjectListImg = styled.img`
  width: 320px;
  height: 180px;
  object-fit: contain;
`

const ProjectContentContainer = styled.div`
  margin: ${props => props.theme.spacing(1, 2)};
  flex: 1;
  min-width: 320px;
  
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ProjectImgContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 640px) {
    flex-grow: 1;
  }
`

export const ProjectListItem: React.FC<ProjectListItemProps> = ({
  className,
  imgClassName,
  children,
  src,
  ...imgProps
}) => {
  return (
    <ProjectListItemContainer className={className}>
      {src && <ProjectImgContainer><ProjectListImg {...imgProps} src={src} className={imgClassName} /></ProjectImgContainer>}
      <ProjectContentContainer>
        {children}
      </ProjectContentContainer>
    </ProjectListItemContainer>
  )
}