import { graphql, useStaticQuery } from "gatsby";

const seoSiteQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`

export interface SeoSiteQuery {
  site: {
    siteMetadata: {
      defaultTitle: string;
      titleTemplate: string;
      defaultDescription: string;
      siteUrl: string;
    };
  };
}

export const useSeoSiteQuery = () => {
  const { site: { siteMetadata } } = useStaticQuery<SeoSiteQuery>(seoSiteQuery);


  return siteMetadata;
}
