import * as React from 'react';
import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    margin-bottom: ${props => props.theme.spacing(4)};
  }
`