import * as React from 'react';
import { PageContainer } from 'components/PageContainer';
import { Typography } from 'components/Typography';
import {
  ProjectList,
  ProjectListItem,
  ProjectListItemTitle,
  ProjectListItemDate,
  ProjectListItemText
} from 'components/ProjectList';
import { ExternalPageLink } from 'components/ExternalPageLink';
import { FullWidthDivider } from 'components/Divider';
import { ProjectsPageColumn } from './ProjectsPageColumn';
import { Translate } from 'components/Translate';

export const ProjectsIT: React.FC = () => {
  return (
    <PageContainer id='it_projects'>
      <ProjectsPageColumn alignItems='center' justifyContent='center'>
        <Typography variant='header1'><Translate label='topMenu.itProjects' /></Typography>
        <ProjectList>
          <ProjectListItem
            src='https://media-exp1.licdn.com/dms/image/C4E0BAQE8wn1gsBY_NA/company-logo_200_200/0?e=2159024400&v=beta&t=2U9-K8aXrvcwJTf7wcYbkoehCsi2f9IEXtwPlUSC9qs'
          >
            <ProjectListItemTitle>Beta District</ProjectListItemTitle>
            <ProjectListItemDate>06.2019 - <Translate label='common.now' /></ProjectListItemDate>
            <ProjectListItemText>
              <Typography variant='caption'>
                <Translate label='itProjectsList.frontendEngineer' />
              </Typography>
              <Translate label='itProjectsList.companyProductNDA' />
              <div>
                <Translate label='itProjectsList.technologyStackBeta' />
                <Translate label='itProjectsList.materialDesignKnowledge' />
              </div>
            </ProjectListItemText>
          </ProjectListItem>
          <FullWidthDivider />
          <ProjectListItem
            src='https://www.comarch.pl/dist/img/portal/Comarch_Globabl_IT.png'
          >
            <ProjectListItemTitle>Comarch</ProjectListItemTitle>
            <ProjectListItemDate>07.2018 - 10.2018</ProjectListItemDate>
            <ProjectListItemText>
              <Typography variant='caption'>
                <Translate label='itProjectsList.backendEngineer' />
              </Typography>
              <ExternalPageLink
                href='https://www.comarch.com/telecommunications/oss-solutions/service-fulfillment/'
              >
                OSS NGSF
              </ExternalPageLink>
              {' '}<Translate label='common.project' />{'. '}
              <div>
                <Translate label='itProjectsList.technologyStackComarch' />
              </div>
            </ProjectListItemText>
          </ProjectListItem>
        </ProjectList>
      </ProjectsPageColumn>
    </PageContainer>
  )
}